export default function Maintenance() {
  return (
    <section className="max-width-container">
      <div className="main-width-container">
        <div className="padding-container my-16">
          <div className="text-center text-blue-550">
            <p className="font-sans font-normal text-[20px] md:text-[30px] lg:text-[40px] leading-[50px] lg:leading-[74px] mt-20">
              WE ARE COMING SOON
            </p>
            <p className="font-sans font-normal text-[20px] md:text-[40px] lg:text-[70px] leading-[80px] lg:leading-[107px]">
              This Page under maintenance!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
