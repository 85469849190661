import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '@/views/home/Home';
import {
  AllJobsBySpecialty,
  AllJobsByState,
  Compliance,
  ContactUs,
  EmployerRegistration,
  EmployerService,
  InternationalRecruitment,
  PermanentRecruitment,
  PremierLocumDoctor,
  PremierLocumGP,
  PrivacyPolicy,
  ReferAndEarn,
  Register,
  SpotWorkForce,
  TemporaryRecruitment,
  TermsAndCondition,
} from '@/views';
import AllJobsByLocation from '@/views/dynamic/AllJobsBySateWithRegions';
import AllJobsByPermanentOrLocum from '@/views/dynamic/AllJobsByPermanentOrLocum';
import PageLoadingComponent from '@/components/noData/PageLoadingComponent';
import MegaFilterPage from '@/views/allJobs/MegaFilterPage';
import JobBoardFilter from '@/views/allJobs/JobBoardFilterMain';
import FilterCityMain from '@/views/allJobs/FilterCityMain';
import CandidateSignIn from '@/views/signInPages/CandidateSignIn';
import ClientSignIn from '@/views/signInPages/ClientSignIn';
import WrappedPremierLocumGP from '@/views/cardPages/PremierLocumGP';
import PageNotFound from '@/views/Errors/PageNotFound';
import NotImplemented from '@/views/Errors/NotImaplementted';
import ApiServerError from '@/views/Errors/ServerError';
import TooManyRequest from '@/views/Errors/TooManyRequest';
import SeniorityLandingPage from '@/views/landingPages/SeniorityLandingPage';
import AllMedicalJobs from '@/views/dynamic/AllMedicalJobs';
import CForceNurse from '@/views/cardPages/CForceNurse';
import Maintenance from '@/views/Errors/Maintenance';
// import AllJobsBySeniority from '@/views/dynamic/AllJobsBySeniority';

const MainAllJob = lazy(() => import('@/views/allJobs/MainAllJob'));
const PermanentJob = lazy(() => import('@/views/permanent/PermanentJob'));
const LocumJob = lazy(() => import('@/views/locum/LocumJob'));
const LocationLanding = lazy(
  () => import('@/views/locationLanding/LocationLanding')
);
const ProfessionalLanding = lazy(
  () => import('@/views/ProfessionalLanding/ProfessionalLanding')
);

const AllJobsBySeniority = lazy(
  () => import('@/views/dynamic/AllJobsBySeniority')
);

export default function AppRouter() {
  return (
    <>
      <Suspense fallback={<PageLoadingComponent />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/employer-register" element={<EmployerRegistration />} />
          <Route
            path="/premier-locum-general-practitioner"
            element={<WrappedPremierLocumGP />}
          />
          <Route path="/spot-workforce" element={<SpotWorkForce />} />
          <Route
            path="/premier-locum-doctor"
            element={<PremierLocumDoctor />}
          />
          <Route path="/nursing" element={<CForceNurse />} />
          <Route path="/sign-in" element={<CandidateSignIn />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/client-sign-in" element={<ClientSignIn />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/compliance" element={<Compliance />} />
          <Route path="/employer-service" element={<EmployerService />} />
          <Route
            path="/permanent-recruitment"
            element={<PermanentRecruitment />}
          />
          <Route
            path="/temporary-recruitment"
            element={<TemporaryRecruitment />}
          />
          <Route
            path="/international-recruitment"
            element={<InternationalRecruitment />}
          />
          <Route path="/refer-and-earn" element={<ReferAndEarn />} />
          <Route path="/all-jobs-in-newzealand" element={<MegaFilterPage />} />
          {/* Specific profession job in location route */}
          <Route
            path="/profession/:professionUrl"
            element={<ProfessionalLanding />}
          />

          {/* Generic jobs in location route */}
          <Route path="/state/:locationUrl" element={<LocationLanding />} />
          <Route
            path="/seniority/:seniorityUrl"
            element={<SeniorityLandingPage />}
          />

          {/* Specific professional job in New Zealand route */}
          <Route
            path="/permanent-jobs/:location/:profession-jobs"
            element={<MainAllJob />}
          />
          <Route
            path="/locum-jobs/:location/:profession-jobs"
            element={<MainAllJob />}
          />

          {/* Other Dynamic routes*/}
          <Route
            path="/apply-now/permanent/:title/:jobId"
            element={<PermanentJob />}
          />
          <Route path="/apply-now/locum/:title/:jobId" element={<LocumJob />} />
          {/* General job listings */}
          <Route
            path="/permanent-jobs/:stateName"
            element={<AllJobsByState />}
          />
          <Route path="/locum-jobs/:stateName" element={<AllJobsByState />} />
          <Route
            path="/region/permanent-jobs/:regionName"
            element={<AllJobsByLocation />}
          />
          <Route
            path="/region/locum-jobs/:regionName"
            element={<AllJobsByLocation />}
          />
          <Route path="/city/all-jobs/:cityName" element={<FilterCityMain />} />
          {/* route path for hard code jobs in mega menu */}
          <Route
            path="/all-doctor-jobs-in-newzealand"
            element={<JobBoardFilter />}
          />
          <Route
            path="/all-ahp-jobs-in-newzealand"
            element={<JobBoardFilter />}
          />
          <Route
            path="/all-permanent-doctor-jobs-in-newzealand"
            element={<AllMedicalJobs />}
          />
          <Route
            path="/all-locum-doctor-jobs-in-newzealand"
            element={<AllMedicalJobs />}
          />
          <Route
            path="/all-permanent-occupational-therapists-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-locum-occupational-therapists-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-permanent-speech-pathologist-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-locum-speech-pathologist-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-permanent-speech-therapist-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-locum-speech-therapist-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-permanent-physiotherapist-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-locum-physiotherapist-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-permanent-dentists-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-locum-dentists-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-permanent-nurses-jobs-in-newzealand"
            element={<AllMedicalJobs />}
          />
          <Route
            path="/all-locum-nurses-jobs-in-newzealand"
            element={<AllMedicalJobs />}
          />
          <Route
            path="/all-permanent-psychology-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-locum-psychology-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/all-permanent-:profession-jobs-in-newzealand"
            element={<AllJobsByPermanentOrLocum />}
          />
          <Route
            path="/specialty/permanent/:specialty-jobs-in-newzealand"
            element={<AllJobsBySpecialty />}
          />
          <Route
            path="/specialty/locum/:specialty-jobs-in-newzealand"
            element={<AllJobsBySpecialty />}
          />
          <Route
            path="/seniority/permanent/:seniority-jobs-in-newzealand"
            element={<AllJobsBySeniority />}
          />
          <Route
            path="/seniority/locum/:seniority-jobs-in-newzealand"
            element={<AllJobsBySeniority />}
          />
          <Route path="*" element={<Navigate to="/page-not-found" />} />
          <Route path="/page-not-found" element={<PageNotFound />} />
          <Route path="/server-error" element={<ApiServerError />} />
          <Route path="/not-implemented" element={<NotImplemented />} />
          <Route path="/many-requests" element={<TooManyRequest />} />
          <Route path="/maintenance" element={<Maintenance />} />
        </Routes>
      </Suspense>
    </>
  );
}
