import { motion } from 'framer-motion';
import {
  MegaMenuProps,
  Profession,
  Seniority,
  Specialty,
} from '@/types/FetchDataTypes';
import { Link } from 'react-router-dom';
import { formatNameForURL } from '@/utils/formatNameForURL';
import useTopMegaMenu from '@/hooks/useScollTopNavMenu';
import {
  AWS_BASE_ASSETS_MENU_ICONS,
  AWS_BASE_URL_ASSETS,
} from '@/config/seviceApiConfig';
import DoctorImg from '/icons/Medical Director Jobs.png';

const menuVariants = {
  hidden: {
    opacity: 0.9,
    scale: 0.98,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.25,
      when: 'beforeChildren',
      staggerChildren: 0.05,
    },
  },
};

const itemVariants = {
  hidden: {
    opacity: 0.9,
    y: -5,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
    },
  },
  hover: {
    scale: 1.02,
    fontWeight: 'bold',
    transition: {
      duration: 0.3,
    },
  },
};

export default function MegaMenu({ link, isOpen }: MegaMenuProps) {
  const { name, professions } = link;
  const { handleNavigation } = useTopMegaMenu();

  const allSeniorities: Seniority[] = [];
  const allSpecialities: Specialty[] = [];

  professions.forEach((profession: Profession) => {
    if (profession.seniorities) {
      allSeniorities.push(...profession.seniorities);
    }
    if (profession.specilities) {
      allSpecialities.push(...profession.specilities);
    }
  });

  const renderImage = () => {
    if (name === 'Medical') {
      return (
        <>
          <Link
            to="/premier-locum-general-practitioner"
            onClick={handleNavigation}
          >
            <img
              src={`${AWS_BASE_URL_ASSETS}/premierlocumgp.png`}
              alt="general-practitioner"
              className="mb-5 mt-5 w-[192px] h-[90px]"
            />
          </Link>
          <Link to="/maintenance" onClick={handleNavigation}>
            <img
              src={`${AWS_BASE_URL_ASSETS}/premierlocumdoctor.png`}
              alt="premier-doctor"
              className="mb-5 w-[192px] h-[90px]"
            />
          </Link>
        </>
      );
    } else if (name === 'AHP') {
      return (
        <>
          <Link to="/maintenance" onClick={handleNavigation}>
            <img
              src={`${AWS_BASE_URL_ASSETS}/spotworkforce.png`}
              alt="spot-workforce"
              className="w-[192px] h-[90px] mt-5"
            />
          </Link>
        </>
      );
    } else if (name === 'Nursing') {
      return (
        <>
          <Link to="/maintenance" onClick={handleNavigation}>
            <img
              src={`${AWS_BASE_URL_ASSETS}/nurse.png`}
              alt="spot-workforce"
              className="w-[192px] h-[90px] mt-5"
            />
          </Link>
        </>
      );
    }
    return null;
  };

  return (
    <motion.section
      className={`mega-menu absolute lg:top-[3.125rem] xl:top-[4rem] lg:w-[45.91rem] xl:w-[810px] 2xl:w-[64rem] right-0 backdrop-blur-lg h-auto`}
      initial="hidden"
      animate={isOpen ? 'visible' : 'hidden'}
      variants={menuVariants}
    >
      <div className="flex items-start justify-between w-full bg-gradient-to-r from-white to-gray-150 border border-white rounded-b-md shadow p-5">
        <div className="flex items-start justify-between">
          <div className="mr-6">
            <ul className="grid grid-cols-3 gap-x-3 gap-y-4">
              {allSpecialities.map((speciality) => (
                <motion.li
                  key={speciality.specialities_id}
                  variants={itemVariants}
                >
                  <motion.div
                    className="flex items-center justify-start gap-x-3"
                    variants={itemVariants}
                    whileHover="hover"
                  >
                    <img
                      src={`${AWS_BASE_ASSETS_MENU_ICONS}${speciality.menu_icon}`}
                      className="w-[20px] h-[20px] object-contain object-center"
                    />
                    <span className="leading-[20px] mr-2 2xl:mr-4 cursor-pointer text-blue-500 text-xs 2xl:text-[14px] font-[700]">
                      <Link
                        to={
                          speciality?.short_name === 'All Jobs - Doctor'
                            ? '/all-doctor-jobs-in-newzealand'
                            : speciality?.short_name === 'All Jobs - AHP'
                              ? '/all-ahp-jobs-in-newzealand'
                              : `/specialty/permanent/${formatNameForURL(
                                  (
                                    speciality?.short_name?.replace(
                                      /jobs/i,
                                      ''
                                    ) || ''
                                  ).trim()
                                )}-jobs-in-newzealand`
                        }
                      >
                        {speciality?.short_name}
                      </Link>
                    </span>
                  </motion.div>
                  <p className="2xl:w-[166px] text-[10px] 2xl:text-xs leading-[17px] text-blue-230 ml-8">
                    {speciality.menu_description}
                  </p>
                </motion.li>
              ))}
            </ul>
          </div>
          <div className="w-[290px] bg-white bg-opacity-[80%] p-4 rounded-lg">
            <div className="text-blue-400 font-sans font-normal text-sm leading-[24px]">
              <h1 className="text-blue-700 font-bold text-sm mb-3 2xl:mb-5">
                Explore More
              </h1>
              <ul>
                {allSeniorities.map((seniority) => (
                  <motion.li
                    key={seniority.seniorities_id}
                    variants={itemVariants}
                    whileHover="hover"
                  >
                    <span className="leading-[24px] cursor-pointer">
                      <Link to={`/seniority/${seniority.url}`}>
                        {seniority?.short_name}
                      </Link>
                    </span>
                  </motion.li>
                ))}
              </ul>
              {name === 'Medical' && (
                <div className="mt-10">
                  <ul>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={'/all-permanent-doctor-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Doctor - Permanent Jobs
                      </Link>
                    </motion.li>
                    <motion.li variants={itemVariants}>
                      <Link
                        to={'/all-locum-doctor-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Doctor - Locum Jobs
                      </Link>
                    </motion.li>
                    {/* <motion.li
                      className="mt-6"
                      variants={itemVariants}
                      whileHover="hover"
                    >
                      <Link
                        to={`/specialty/permanent/${formatNameForURL(
                          'GP Tele Health Jobs'.replace(/jobs/i, '').trim()
                        )}-jobs-in-australia`}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        GP Tele Health Jobs
                      </Link>
                    </motion.li> */}
                  </ul>
                </div>
              )}
              {name === 'AHP' && (
                <div className="">
                  <ul>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={
                          '/all-permanent-occupational-therapists-jobs-in-newzealand'
                        }
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        OT - Permanent Jobs
                      </Link>
                    </motion.li>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={
                          '/all-locum-occupational-therapists-jobs-in-newzealand'
                        }
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        OT - Locum Jobs
                      </Link>
                    </motion.li>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={
                          '/all-permanent-speech-pathologist-jobs-in-newzealand'
                        }
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Speech Pathologist - Permanent Jobs
                      </Link>
                    </motion.li>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={'/all-locum-speech-pathologist-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Speech Pathologist - Locum Jobs
                      </Link>
                    </motion.li>
                    <motion.li variants={itemVariants}>
                      <Link
                        to={'/all-permanent-physiotherapist-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Physiotherapist - Permanent Jobs
                      </Link>
                    </motion.li>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={'/all-locum-physiotherapist-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Physiotherapist- Locum Jobs
                      </Link>
                    </motion.li>
                  </ul>
                </div>
              )}
              {name === 'Oral Health' && (
                <div className="mt-10">
                  <ul>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={'/all-permanent-dentists-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Permanent Dentist Jobs
                      </Link>
                    </motion.li>
                    <motion.li variants={itemVariants}>
                      <Link
                        to={'/all-locum-dentists-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Locum Dentist Jobs
                      </Link>
                    </motion.li>
                  </ul>
                </div>
              )}
              {name === 'Nursing' && (
                <div className="mt-10">
                  <ul>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={'/all-permanent-nurses-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Permanent Nursing Jobs
                      </Link>
                    </motion.li>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={'/all-locum-nurses-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Locum Nursing Jobs
                      </Link>
                    </motion.li>
                  </ul>
                </div>
              )}
              {name === 'Mental Health' && (
                <div className="mt-10">
                  <ul>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={'/all-permanent-psychology-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Permanent Psychology Jobs
                      </Link>
                    </motion.li>
                    <motion.li variants={itemVariants} whileHover="hover">
                      <Link
                        to={'/all-locum-psychology-jobs-in-newzealand'}
                        className="leading-[24px] mr-4 hover:font-bold cursor-pointer"
                      >
                        Locum Psychology Jobs
                      </Link>
                    </motion.li>
                  </ul>
                </div>
              )}
              <div className="">{renderImage()}</div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
}
