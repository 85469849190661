import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import ContextProvider from './context/ContextProvider';
import { HelmetProvider } from 'react-helmet-async';

if (import.meta.env.VITE_DEBUG === 'false') {
  console.log = function () {};
  console.error = function () {};
  console.info = function () {};
  console.warn = function () {};
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ContextProvider>
          <App />
        </ContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
